export const siteName = 'Baudarlehen24';

export const getConsultantUrl = (slug) => `/berater/${slug}`;

export const gdprConfig = {
  acceptAll: true,
  htmlTexts: true,
  translations: {
    de: {
      acceptAll: 'Alle Cookies akzeptieren',
      ok: 'Alle Cookies akzeptieren',
      decline: 'Alle optionalen Cookies ablehnen',
      acceptSelected: 'Individuelle Auswahl bestätigen',
      privacyPolicyUrl: '/datenschutz',
      consentNotice: {
        description: '<div data-nosnippet><div class="klaro-brand-logo"><img src="/images/brand/brand-logo-inverted.svg" /></div>Um unser Webangebot zu verbessern und diese Seite optimal für unsere Besucher zu gestalten, setzen wir und auch unsere Partner, dessen Dienste wir nutzen, Technologien wie Cookies ein. Auch ein Austausch von IP-Adressen unserer Webseitenbesucher mit unseren Partnern kann stattfinden.<br /><br />Die Setzung einiger Cookies ist zwingend erforderlich. Für bestimmte Dienste benötigen wir Ihre Einwilligung.<br /><br />Durch den Klick auf „Alle Cookies akzeptieren“, willigen Sie (jederzeit für die Zukunft widerruflich) in alle Datenverarbeitungen (Setzung von Cookies und Übermittlung der IP-Adresse an Partner) ein.<br /><br />Durch den Klick „ Alle optionalen Cookies ablehnen“ werden alle nicht zwingend notwendigen Cookies nicht gesetzt und Verbindungen unterbunden. Die Nutzung unserer Webseite ist dann stark eingeschränkt.<br /><br />Durch den Klick auf „ Lassen Sie mich wählen“ können Einstellungen geändert und der Datenverarbeitung eingewilligt werden. Ihre Auswahl kann jederzeit angepasst werden.<br /><br />Hinweis auf Verarbeitung der Daten in den USA (z.B. durch Google, Facebook, Youtube): Durch den Klick auf „Alle Cookies akzeptieren" oder bei der entsprechenden Auswahl eines Anbieters, willigen Sie zugleich darin ein, dass Ihre Daten in den USA verarbeitet werden. Die USA wird als ein Land mit einem nicht ausreichenden Datenschutzniveau angesehen. Es besteht u.a. das Risiko, dass Ihre Daten durch US-Behörden, zu Kontroll- und zu Überwachungszwecken, möglicherweise auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden können. Bei dem Klick auf „Alle optionalen Cookies ablehnen“, findet keine Datenübermittlung statt.<br /><br />Weitere Informationen finden Sie unter <a href="/datenschutz">Datenschutz</a>. Zum <a href="/impressum">Impressum</a>.</div>',
      },
      privacyPolicy: {
        name: 'Datenschutz',
        text: 'Weitere Informationen finden Sie unter {privacyPolicy}. Zum <a href="/impressum" target="_blank">Impressum</a>.<br /><br />Hier finden Sie eine Übersicht über alle verwendeten Cookies und eingebundener Dienste und können eine individuelle Auswahl treffen: '
      },
      consentModal: {
        description:
          'Durch den Klick auf „Alle Cookies akzeptieren“, willigen Sie (jederzeit für die Zukunft widerruflich) in alle Datenverarbeitungen (Setzung von Cookies und Übermittlung der IP-Adresse an Partner) ein.<br /><br />Durch den Klick „Alle optionalen Cookies ablehnen“ werden alle nicht zwingend notwendigen Cookies nicht gesetzt und Verbindungen unterbunden. Die Nutzung unserer Webseite ist dann stark eingeschränkt.<br /><br />Hinweis auf Verarbeitung der Daten in den USA (z.B. durch Google, Facebook, Youtube): Durch den Klick auf „Alle Cookies akzeptieren" oder bei der entsprechenden Auswahl eines Anbieters, willigen Sie zugleich darin ein, dass Ihre Daten in den USA verarbeitet werden. Die USA wird als ein Land mit einem nicht ausreichenden Datenschutzniveau angesehen. Es besteht u.a. das Risiko, dass Ihre Daten durch US-Behörden, zu Kontroll- und zu Überwachungszwecken, möglicherweise auch ohne Rechtsbehelfsmöglichkeiten, verarbeitet werden können.<br /><br />',
      },
      contextualConsent: {
        description: 'Möchten Sie von {title} bereitgestellte externe Inhalte laden?',
        acceptAlways: 'Weiter'
      },
      purposes: {
        essential: {
          title: 'Essenziell',
          description: 'Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.'
        },
        analytics: {
          title: 'Statistiken',
          description: 'Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie Besucher unsere Website nutzen.'
        },
        marketing: {
          title: 'Marketing',
          description: 'Marketing Cookies werden von Drittanbietern oder Publishern verwendet, um personalisierte Werbung anzuzeigen. Sie tun dies, indem sie Besucher über Websites hinweg verfolgen.'
        },
        externalContent: {
          title: 'Externe Medien',
          description: 'Inhalte von Videoplattformen und Social-Media Plattformen werden standardmäßig blockiert. Wenn Cookies von externen Medien akzeptiert werden, bedarf der Zugriff auf diese Inhalte keiner manuellen Einwilligung mehr.'
        }
      }
    }
  },
  services: [
    // essential
    // analytics
    {
      name: 'googleAnalytics',
      title: 'Google Tag Manager & Google Anayltics',
      purposes: ['analytics'],
      description: createServiceDescription({
        vendor: 'Google Ireland Limited',
        purpose: 'Cookie von Google zur Steuerung von Google Analytics und der erweiterten Script- und Ereignisbehandlung.',
        privacyUrl: 'https://policies.google.com/privacy',
        cookies: [
          { name: '_gid', expire: '1 Tag' },
          { name: '_ga', expire: '2 Jahre' },
          { name: '_gat_gtag_UA_62061092_1', expire: '1 Tag' },
          { name: 'IDE', expire: '1 Jahr' },
          { name: 'RUL', expire: '1 Jahr' },
        ]
      })
    },
    // marketing
    {
      name: 'facebook',
      title: 'Facebook Tracking Pixel',
      purposes: ['marketing'],
      description: createServiceDescription({
        vendor: 'Facebook Ireland Limited',
        purpose: 'Durch diesen Cookie kann das Verhalten von Nutzern nachverfolgt werden. Dieses Verfahren dient dazu, die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke auszuwerten und kann dazu beitragen, zukünftige Werbemaßnahmen zu optimieren.',
        privacyUrl: 'https://www.facebook.com/about/privacy',
        cookies: [
          { name: 'fr', expire: '3 Monate' },
          { name: '_fbp', expire: '3 Monate' },
          { name: 'sb', expire: '2 Jahre' },
          { name: 'datr', expire: '2 Jahre' },
          { name: 'dpr', expire: '2 Wochen' },
          { name: 'wd', expire: '2 Wochen' },
        ]
      })
    },
    // externalContent
    {
      name: 'googleMaps',
      title: 'Google Maps',
      purposes: ['externalContent'],
      description: createServiceDescription({
        vendor: 'Google Ireland Limited',
        purpose: 'Wird zum Entsperren von Google-Maps Kartendiensten verwendet.',
        privacyUrl: 'https://policies.google.com/privacy',
      })
    },
    {
      name: 'youtube',
      title: 'Youtube',
      purposes: ['externalContent'],
      description: createServiceDescription({
        vendor: 'Google Ireland Limited',
        purpose: 'Wird zum Entsperren von Youtube Videos verwendet.',
        privacyUrl: 'https://policies.google.com/privacy',
        cookies: [
          { name: '__Secure-3PAPISID', expire: '2 Jahre' },
          { name: '__Secure-3PSID', expire: '2 Jahre' },
          { name: '__Secure-3PSIDCC', expire: '1 Jahr' },
          { name: 'CONSENT', expire: '20 Jahre' },
          { name: 'GPS', expire: '1 Tag' },
          { name: '_gcl_au', expire: '2 Monate' },
          { name: 'VISITOR_INFO1_LIVE', expire: '6 Monate' },
          { name: 'YSC', expire: 'Session' },
          { name: 'wide', expire: 'Session' },
          { name: 'PREF', expire: '2 Jahre' },
        ]
      })
    },
    {
      name: 'provenexpert',
      title: 'Proven Expert',
      purposes: ['externalContent'],
      description: createServiceDescription({
        vendor: 'Expert Systems AG',
        purpose: 'Wird zum Entsperren von Unternehmens-Bewertungs-Widgets von Proven Expert verwendet.',
        privacyUrl: 'https://www.provenexpert.com/de-de/datenschutzbestimmungen/'
      })
    },
  ],
};

function createServiceDescription(data) {
  const {
    vendor,
    purpose,
    privacyUrl,
    cookies
  } = data;

  let description = '<div class="service-details-container"><table class="service-details"><tbody>';

  if (vendor) {
    description += `<tr><td>Anbieter</td><td>${vendor}</td></tr>`;
  }

  if (purpose) {
    description += `<tr><td>Zweck</td><td>${purpose}</td></tr>`;
  }

  if (privacyUrl) {
    description += `<tr><td>Datenschutzerklärung</td><td><a href="${privacyUrl}" target="_blank">${privacyUrl}</a></td></tr>`;
  }

  if (cookies && cookies.length > 0) {
    description += '<tr><td>Cookies</td><td>';

    for (const { name, expire } of cookies) {
      description += `${name} (Laufzeit: ${expire})<br />`;
    }

    description += '</td></tr>';
  }

  description += '</tbody></table></div>';

  return description;
}
