import '../polyfills';
import '../lib/store';

import * as Sentry from '@sentry/browser';

import { ApolloProvider, gql } from '@apollo/client';
import { ThemeProvider, withStyles } from '@material-ui/styles';
import { initializeApollo, useApollo, withRedux } from '../state';
import theme, {
  globalStyles,
  createThemeWithOverrides
} from '../theme';

import App from 'next/app';
import { ButtonElement } from '@plugins/next-cms-core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';
import { SessionProvider } from 'next-auth/react';
import WebsiteContainer from '@components/container/WebsiteContainer';
import WidgetContainer from '@components/container/WidgetContainer';
import checkForUtmCampaign from '@lib/utmCampaign';
import getConfig from 'next/config';
import { setLocale } from 'yup';
import merge from 'lodash/merge';
import yupLocale from '../localeDE';

const { publicRuntimeConfig } = getConfig();
setLocale(yupLocale);

if (publicRuntimeConfig.SENTRY_DSN) {
  // https://leerob.io/blog/configuring-sentry-for-nextjs-apps
  Sentry.init({
    enabled: !publicRuntimeConfig.isDev,
    dsn: publicRuntimeConfig.SENTRY_DSN
  });
}

class MyApp extends App {
  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    checkForUtmCampaign();

    import('../browserUpdate');
  }

  static async getInitialProps({ Component, ctx }) {
    // On the server, initialize a new graphql client
    // on every request
    const graphQlClient = initializeApollo();
    ctx.graphQlClient = graphQlClient;

    // Default init query
    const { data } = await graphQlClient.query({
      query: DEFAULT_QUERY,
      variables: {
        path: ctx.asPath
      }
    });

    let widget = null;

    if (Component.layoutContainer === 'widget') {
      const { data: widgetData } = await graphQlClient.query({
        query: WIDGET_QUERY,
        variables: {
          id: ctx.query.id
        }
      });

      widget = widgetData && widgetData.widgets.length > 0
        ? widgetData.widgets[0]
        : null;
    }

    // We can dispatch from here too
    // ctx.store.dispatch({ type: 'FOO', payload: 'foo' });

    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx) : {};

    const provenExpertGoogleStars = await fetch(`${publicRuntimeConfig.PUBLIC_URL}/api/provenexpert/googlestars`);

    return {
      pageProps: {
        ...pageProps,
        appData: data,
        defaultHubSpotInstance: data.hubSpotInstances[0],
        widget,
        provenExpertGoogleStars: await provenExpertGoogleStars.json(),
        initialGraphQlState: graphQlClient.cache.extract()
      },
    };
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Head>
        <AppContainer Component={Component} pageProps={pageProps}/>
      </>
    );
  }
}

function AppContainer({ Component, pageProps }) {
  const graphQlClient = useApollo(pageProps.initialGraphQlState);
  let ContainerComponent = WebsiteContainer;

  if (Component.layoutContainer === 'widget') {
    ContainerComponent = WidgetContainer;
  }

  return (
    <>
      <Head>
        <meta property="og:image" content="/images/og-image-default.png" key="og:image"/>
        <meta name="twitter:image" content="/images/og-image-default.png" key="twitter:image"/>
      </Head>
      <ThemeProvider
        theme={
          (pageProps.widget?.branding?.theme || pageProps.widget?.theme)
            ? createThemeWithOverrides(merge(
              pageProps.widget.branding?.theme ?? {},
              pageProps.widget.theme ?? {}
            ))
            : theme
        }
      >
        <ApolloProvider client={graphQlClient}>
          <SessionProvider session={pageProps.session}>
            {/* CssBaseline kickstart an elegant, consistent, and
          simple baseline to build upon. */}
            <CssBaseline/>
            <ContainerComponent pageProps={pageProps}>
              <Component {...pageProps} />
            </ContainerComponent>
            <GlobalStyles/>
          </SessionProvider>
        </ApolloProvider>
      </ThemeProvider>
      <style global jsx>{`
        body {
          overflow-y: scroll;
          transition: background-color 250ms ease-in-out;
          color: ${theme.palette.text.primary} !important;
          background-color: ${theme.palette.secondary.main} !important;
        }

        .no-scroll {
          overflow-y: hidden !important;
        }

        #__next {
          display: flex;
          min-height: 100vh;
          flex-direction: column;
        }

        a {
          color: inherit;
          text-decoration: none;
        }

        a:focus, a:hover {
          text-decoration: underline;
        }

        p a {
          color: ${theme.palette.primary.main};
          text-decoration: none !important;
          background-image: linear-gradient(to right, ${theme.palette.primary.main} 25%, rgba(255, 255, 255, 0) 0%);
          background-position: bottom;
          background-size: 4px 1px;
          background-repeat: repeat-x;
        }

        p a:focus, p a:hover {
          color: ${theme.palette.primary.dark};
          background-image: linear-gradient(to right, ${theme.palette.primary.dark} 25%, ${theme.palette.primary.dark} 0%);
        }

        .img-fluid {
          max-width: 100%;
          height: auto;
        }

        svg.icon {
          display: block;
        }

        h1, h2, h3, h4, h5, h6, p {
          margin-block-start: 0;
          margin-block-end: 0;
        }

        .onpage-anchor {
        }

        .brand_logo_origin {
          display: none;
        }
      `}</style>
    </>
  );
}

AppContainer.propTypes = {
  Component: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.object.isRequired,
};

export function reportWebVitals(metric) {
  if (!publicRuntimeConfig.isDev) {
    return;
  }

  //console.log(metric);
}

const GlobalStyles = withStyles(globalStyles)(() => null);

export default withRedux(MyApp);

const DEFAULT_QUERY = gql`
  query Default($path: String!) {
    hubSpotInstances(where: { isDefault: true }) {
      instanceId
    }
    siteConfig {
      version
      is_cache_enabled
      whatsapp_fab_phone
      sidebar_phone
      sidebar_url_contact
      sidebar_url_consultants
      sidebar_url_financing
    }
    siteNotice {
      is_global_notice_visible
      global_notice_title
      global_notice_text
      global_notice_buttons {
        ${ButtonElement.graphQlSchema}
      }
    }
    pageHeadConfig {
      is_top_bar_enabled
    }
    pageBottomConfig {
      global_info_text
    }
    navigationMain: renderNavigation(idOrSlug: "main-navigation", type: TREE)
    renderBreadcrumbPath(path: $path) {
      id
      url
      title
    }
  }
`;

const WIDGET_QUERY = gql`
  query Widget($id: ID!) {
    widgets(where: { widget_id: $id }) {
      id
      name
      widget_id
      source
      theme
      is_footer_link_disabled
      hubspotUserId
      hub_spot_instance {
        instanceId
      }
      branding {
        theme
        is_widget_footer_visible
        is_proven_expert_visible
        is_slogan_visible
        is_footer_link_disabled
        slogan
        logo {
          url
          formats
        }
        logo_width
      }
    }
  }
`;
